import React, {useRef, useEffect} from 'react';

import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonFabButton, IonFab } from '@ionic/react';

import Header from '../components/Header';
import PopupMenu from '../components/PopupMenu';
import Home from '../components/Home';
import AboutUs from '../components/AboutUs';
import ContactUs from '../components/ContactUs';
import Privacy from '../components/Privacy';
import Services from '../components/Services';
import Blogs from '../components/Blogs';

import { arrowUpCircle } from 'ionicons/icons';

import './Main.css';

import appState  from '../state/State';
import { observer} from 'mobx-react-lite';
//import { debug } from 'console';

const Main: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  
  //const scrollToTop = (ref:any) => {
  const scrollToTop = () => {  
    if (contentRef) {
        if (contentRef.current)
            contentRef.current && contentRef.current.scrollToTop(500);
    }
  }

  const checkIfAtTop = (e:any) => {
    //console.log(e)
    if (e.target.detail.scrollTop === 0) {
      appState.setScrollAtTop(true)
      return    
    }
    appState.setScrollAtTop(false)
    return    
  }

  useEffect(() => {
   
    appState.setScrollFn(scrollToTop);
  })

  return (
    <div >
    <IonPage  >
      
      <Header/>        
      <PopupMenu/>
          <IonContent ref={contentRef}                   
                      scrollEvents={true}                      
                      onIonScrollEnd={(e) => {checkIfAtTop(e)}}>
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow >
                <IonCol size="12">
                  <div className="main-display-area">
                    <div className={appState.currPage === 'home' ? "enter" : "exit"}>
                      <Home/>    
                    </div>                                                  
                    <div className={appState.currPage === 'aboutus' ? "enter" : "exit"}>
                      <AboutUs/>
                    </div>                                                  
                    <div className={appState.currPage === 'contactus' ? "enter" : "exit"}>
                      <ContactUs/>
                    </div>                                                  
                    <div className={appState.currPage === 'privacy' ? "enter" : "exit"}>
                      <Privacy/>
                    </div>                                                  
                    <div className={appState.currPage === 'services' ? "enter" : "exit"}>
                      <Services/>
                    </div>                                                  
                    <div className={appState.currPage === 'blog' ? "enter" : "exit"}>
                      <Blogs/>
                    </div>                                                  
                  </div>
                </IonCol>                          
              </IonRow>              
            </IonGrid>
            {appState.UXScrollAtTop ? 
              null 
              :
              <IonFab slot="fixed" horizontal='end' vertical='bottom' className="fab">
                <IonFabButton  onClick={scrollToTop} className="fab-button">
                  <IonIcon className="fab-icon" icon={arrowUpCircle}></IonIcon>
                </IonFabButton>
              </IonFab>
            }   
          </IonContent>        
    </IonPage>
    </div>
  );
};

export default observer(Main);
