import React from "react";

import { IonList, IonItem, IonButton } from '@ionic/react';

import { observer} from 'mobx-react-lite';
import appState  from '../state/State';

import './PopupMenu.css';


const PopupMenu: React.FC = () => {
  
  return (    
    <div className={appState.UXmenuIsOpen ? "popup-menu popup-menu-open ion-hide-md-up" : "popup-menu"} >
      <IonList className="popup-menu-background-color ion-no-padding">
      { 
        appState.MenuItems.map((m, index) => {
          return (      
            <IonItem key={index + m.key}lines="none" className="popup-menu-item">
              <IonButton fill="clear" className="popup-menu-nav-button" onClick={() => appState.navigate(m.key)}>
                <span className="ion-text-left"><b>{m.name}</b></span>
              </IonButton>
            </IonItem>
          )
        })
      }
      </IonList>
    </div>
    
  )
};

export default observer(PopupMenu);

