
import { IonCard, IonCardContent } from '@ionic/react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';

import './Clients.css';

import ActGovtImg from '../assets/ACTGovtLogo.png';
import CoCreationsImg from '../assets/CoCreationsLogo.png';
import SuncorpImg from '../assets/SuncorpLogo.png';
import BACImg from '../assets/BACLogo.gif';
import GTTCImg from '../assets/GTTCLogo.jpg';
import TafeNSWImg from '../assets/TafeNSWLogo.jpg';
import TasGovtImg from '../assets/TasGovtLogo.png';
import QldGovtImg from '../assets/QldGovtLogo.png';



import { observer} from 'mobx-react-lite';
//import appState  from '../state/State';


const Clients: React.FC = () => {
  return (  
     
    <IonCard className="card-no-border">    
        <IonCardContent>            
            <IonGrid >
                <IonRow  className="ion-align-items-center ion-justify-content-center">            
                    <IonCol  >
                        <IonImg src={TasGovtImg} className="ion-align-self-center client-logo"/>
                    </IonCol>   
                    <IonCol  >
                        <IonImg src={QldGovtImg} className="client-logo"/>         
                    </IonCol>  
                    <IonCol  >
                        <IonImg src={ActGovtImg} className="client-logo"/>
                    </IonCol>         
                    <IonCol  >
                        <IonImg src={TafeNSWImg} className="client-logo"/>
                    </IonCol>  
                </IonRow>
                <IonRow  className="ion-align-items-center ion-justify-content-center">        
                    <IonCol  >
                        <IonImg src={GTTCImg} className="client-logo"/>
                    </IonCol>   
                    <IonCol  >
                        <IonImg src={BACImg} className="client-logo"/>
                    </IonCol>  
                    <IonCol  >
                        <IonImg src={SuncorpImg} className="client-logo"/>
                    </IonCol>         
                    <IonCol  >
                        <IonImg src={CoCreationsImg} className="client-logo"/>
                    </IonCol>  
                </IonRow>
                
            </IonGrid>        
        </IonCardContent>
    </IonCard>
    
  );
};

export default observer(Clients);
