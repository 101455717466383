import React from "react";

import { IonHeader, IonButton, IonIcon } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';


import {menuOutline} from "ionicons/icons";
import {closeOutline} from "ionicons/icons";

import logo from  '../assets/P2C_LOGO.svg';

import './Header.css';
import { observer} from 'mobx-react-lite';
import appState  from '../state/State';

const DesktopMenu = () => {
  return (
    <IonRow className="header-desktop-menu">
    { 
      appState.MenuItems.map((m, index) => {
        return (
          <div key={index}>
          <IonButton  
            fill="clear" 
            className="ion-hide-md-down header-desktop-txt" 
            onClick={() => appState.navigate(m.key)}>
              {m.name}
          </IonButton>
          <IonButton 
            fill="clear" 
            className="ion-hide-sm-up header-mobile-txt" 
            onClick={() => appState.navigate(m.key)}>
              {m.name}
          </IonButton>
          </div>)
      })
    }
    </IonRow>
  );
}

const MenuButtonOpen = () => {
  return (
    <div>
      <IonIcon icon={menuOutline} onClick={toggleMenu} className="header-menu-icon"/>
    </div>
  )
}

const MenuButtonClose = () => {
  return (
    <div>
      <IonIcon icon={closeOutline} onClick={toggleMenu} className="header-menu-icon"/>
    </div>
  )
}

const MobileMenuButton = () => {
  if (appState.UXmenuIsOpen)
    return MenuButtonClose()
  else
    return MenuButtonOpen()  
}


const toggleMenu = () => {
  appState.toggleMenu();
};

const Header: React.FC = () => {
  return (
    <IonHeader className="header">      
      <IonGrid >
        <IonRow >        
          <IonCol size='2' >
            <img src={logo} alt="P2C Logo" className="header-logo"/>
          </IonCol>

          <IonCol size="10" className="ion-hide-md-down">
            {DesktopMenu()}          
          </IonCol>
          
          <IonCol size="8" className="ion-hide-md-up">
            
          </IonCol>    
          
          <IonCol size="2" className="ion-hide-md-up">
            {MobileMenuButton()}
          </IonCol>        
        
        </IonRow>
    </IonGrid>
  </IonHeader>  

  );
};

export default observer(Header);
