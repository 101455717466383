import React from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';
import { IonText } from '@ionic/react';

import './Privacy.css';

import Footer from '../components/Footer';

import { observer} from 'mobx-react-lite';
//import appState  from '../state/State';

const Privacy: React.FC = () => {
  return (         
    <>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonCard >        
                <IonCardHeader ><h1 ><b >Privacy Policy</b></h1></IonCardHeader>
                <IonCardContent>  
                          
                  <p>
                    Phase 2 Consulting makes a commitment to comply with the legislative obligations 
                    outlined in the Personal Information Protection Act 2004 (Tas) (“PIP Act”), 
                    the Privacy Act 1988 (Cth).  We have adopted this Privacy Policy to ensure the 
                    protection of Personal Information that we collect when providing services and 
                    conducting normal day-to-day operations of our business.
                    <br/>
                    <br/>
                    <b>Our Privacy Policy covers:</b>
                  </p>
              
                  <ul>
                    <li>What is Personal Information</li>
                    <li>Personal Information we may collect</li>
                    <li>How we may use or share Personal Information</li>
                    <li>How we secure the Personal Information we collect</li>
                    <li>How you may access the Personal Information that we hold about you</li>
                    <li>Other important information.</li>
                  </ul>

                  <p>
                    <b>What is Personal Information?</b>              
                  </p>
                  <br/>
                  <IonText className="indent">  
                      Personal Information is any information where your identity could reasonably 
                      be ascertained.              
                  </IonText>

                  <br/>
                  <br/>
                  
                  <p>
                    <b>Personal Information we may collect</b>              
                  </p>
                  <IonText className="indent">
                    <p className="indent">
                    Direct queries via our email service, 
                    info@phase2consulting.com.au or "firstname"."surname"@phase2consulting.com.au, or 
                    via the Contact Us page on our website, www.phase2consulting.com.au, are first 
                    stored by our email inbox. 
                    In the course of business, it can be necessary for us to collect Personal Information. This information allows us to
                    </p>
                  </IonText>
                  
                  <ul>
                    <li>Identify who an individual is for the purposes of our business</li>
                    <li>Share Personal Information when asked of us</li>
                    <li>Contact the individual in the ordinary course of business</li>
                    <li>Transact with the individual. </li>
                  </ul>
                  
                  <p className="indent">
                    Without limitation, the type of information we may collect includes:
                  </p>
                    
                  <ul>
                    <li>Personal Information.</li>
                    We may collect personal details such as an individual's name, location, date of birth
                    and other information defined as “Personal Information” in the Privacy Act that allows 
                    us to identify who the individual is.
                    <li>Contact Information.</li> 
                    We may collect information such as an individual's email address, telephone, residential and/or postal 
                    address and other information that allows us to contact the individual.
                    <li>Financial Information.</li> 
                    We may collect financial information related to an individual such as bank details used to transact with us and other information that allows us to transact with the individual and/or provide them with our services.
                    <li>Information an individual sends us.</li> 
                    We may collect any personal correspondence that an individual sends us, or that is sent to us by others about the individual’s activities.
                    <li>Other information.</li>  
                    When an individual contacts us through the internet, we may collect Personal Information using cookies 
                    (if relevant - an individual can adjust their browser's setting to accept or reject cookies).
                    Our website may collect statistics/analytics which may include the IP address of the user.
                  </ul>                    

                  <p>
                    <b>How we may use or share Personal Information</b>
                  </p>
                  <br/>
                  <p className="indent">
                    We may use your Personal Information in any of the following capacities:
                  </p>

                  <ul>
                    <li>Identifying suitable candidates for roles and/or services and securing contract labour hire work.</li>
                    <li>Making payments for contracted labour hire work and reimbursement for relevant and approved personal 
                        expenses.</li>
                    <li>Facilitating travel bookings (including but not limited to accommodation) associated with relevant 
                      and approved travel related to contracted labour hire work.</li>
                    <li>Email Communications.</li>
                  </ul>
                
                  <p className="indent">
                      Following an enquiry, we may share/release your Personal Information for the 
                      following purposes:
                  </p>

                  <ul>
                      <li>Matching candidates to potential employment opportunities.</li>
                      <li>Securing consulting services.</li>
                  </ul>
                                        
                  <p>
                    <b>How we secure the Personal Information we collect</b>
                  </p>
                  <br/>
                  <p className="indent">
                    We hold information in electronic and paper-based records management systems. We take all reasonable steps to ensure that the information we hold is protected from misuse, loss, unauthorised access or disclosure.  This includes appropriately securing our physical facilities and electronic networks.
                    The security of online transactions and the security of communications sent by electronic means or by post cannot be guaranteed. Each individual that provides information to us via the internet or by post does so at their own risk. We cannot accept responsibility for misuse or loss of, or unauthorised access to, Personal Information where the security of information is not within our control.
                    We are not responsible for the privacy or security practices of any third party (including third parties that we are permitted to disclose an individual's Personal Information in accordance with this policy or any applicable laws). The collection and use of an individual's Personal Information by such third parties may be subject to separate privacy and security policies.
                    If an individual suspects any misuse or loss of, or unauthorised access to, their Personal Information, they should let us know immediately.
                    We are not liable for any loss, damage or claim arising out of third party use of the Personal Information where we were authorised to provide the third party with the Personal Information.                      
                  </p>

                  <br/>

                  <p>
                    <b>How you may access the personal information that we hold about you.</b>
                  </p>
                  <br/>
                  <p className="indent">
                    It is an individual's responsibility to provide us with accurate and truthful Personal Information. We cannot be liable for any information that is provided to us that is incorrect.
                    An individual may opt to not have us collect their Personal Information. This may prevent us from offering our services and may terminate any existing arrangement with us.
                    You can ask whether we are keeping Personal Information about you by emailing info@phase2consulting.com.au or using the Contact Us page on our website, www.phase2consulting.com.au.  If we have retained information on file, you may request a copy of it. You may also wish to apply to amend the Personal Information if you believe it to be out-of-date, incomplete, inaccurate or irrelevant. There is no charge for an individual to seek access to or apply to amend their Personal Information.
                    If an individual receives Personal Information that they believe they should not have received, they should 
                    contact us immediately by emailing info@phase2consulting.com.au or using the Contact Us page on our website, 
                    www.phase2consulting.com.au.                        
                  </p>

                  <br/>

                  <p>
                    <b>Policy Changes</b>
                  </p>
                  <br/>
                  <p className="indent">
                  
                    We reserve the right to change, modify or amend our Privacy Policy at any time. If we decide to change this 
                    Privacy Policy, we will post the changes on our website at www.phase2consulting.com.au.  Please refer 
                    back to this Privacy Policy to review any amendments.
                    Please direct all queries about this Privacy Policy and information regarding the retention of your Personal 
                    Information to info@phase2consulting.com.au. 
                    
                  </p>
                
                </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow >
          <IonCol size="12">
            <Footer/>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
)
}
export default observer(Privacy);
