import React from "react";

import { IonGrid, IonRow, IonCol, IonCard } from '@ionic/react';

import './Home.css';

import Footer from '../components/Footer';

import { observer} from 'mobx-react-lite';
//import appState  from '../state/State';

const Home: React.FC = () => {

    return (          
        <>
            <IonGrid>
                <IonRow className="home-row">
                    <IonCol size="12">
                        
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-center home-content-padding">
                    <IonCol size="12">
                        <IonCard className="home-name">
                            <h1 ><b className="home-text-light ">Phase 2 Consulting</b></h1>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="home-row">
                    <IonCol size="12">
                        
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Footer/>
                    </IonCol>
                </IonRow>
            </IonGrid>    
        
        </>        
  );
};

export default observer(Home);
