import React from "react";

import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonCard, IonCardHeader, IonCardContent, IonButton, IonImg } from '@ionic/react';

import PMIImg from '../assets/PMILogoH125.png';
import TasICTImg from '../assets/TasICTLogoH125.png';
import AIPMImg from '../assets/AIPMLogoH125.png';
import AIDHImg from '../assets/AIDHLogoH125.png';

import logoTwitter from '../assets/TwitterBlueH300.png';
import logoLinkedin from '../assets/LinkedInBlueH300.png';

import './Footer.css';

import appState  from '../state/State';
import { observer} from 'mobx-react-lite';

const ContactInfo = () => {

    const gotoSocial = (target:string) => {
        if (target === "twitter") {
            window.open('https://twitter.com/Phs2Consulting','_system', 'location=yes'); return false;
        }
        if (target === "linkedin") {
            window.open('https://www.linkedin.com/company/82532322/admin','_system', 'location=yes'); return false;
        }
    }

    return (
        <IonCard className="footer-color ion-no-padding">
            <IonCardHeader><h1 className="footer-font-color">Phase 2 Consulting</h1></IonCardHeader>
            <IonCardContent >
                <div>
                    <h2>CONTACT DETAILS</h2>
                    <br/>
                    <p>                        
                        GPO Box 174<br/>
                        Hobart  TAS  7001<br/>
                        e: info@phase2consulting.com.au<br/>
                        m: 0419 682 378
                    </p>                        
                    <br/>
                    <div className="desktop-only">
                        <IonRow className="ion-align-items-start">
                            
                                <IonButton  className="footer-social-btn" fill="clear" onClick={() => gotoSocial("linkedin")}>
                                    <IonImg src={logoLinkedin} className="p2c-social"/>
                                </IonButton>
                                <IonButton className="footer-social-btn" fill="clear" onClick={() => gotoSocial("twitter")}>
                                    <IonImg src={logoTwitter} className="p2c-social"/>
                                </IonButton>
                            
                        </IonRow>                    

                        <IonRow className="ion-align-items-start">                            
                            {
                                appState.currPage !== "contactus" ? 
                                <div >
                                    
                                <IonButton  shape="round" 
                                            className="footer-btn"
                                            onClick={() => appState.navigate("contactus")}>
                                    Lets Talk
                                </IonButton>
                                
                                </div>
                                : <></>
                            }     
                            
                        </IonRow>      
                    </div>
                    <div className="mobile-only">
                        <IonRow className="ion-align-items-start`">
                        
                            <IonButton  className="footer-social-btn" fill="clear" onClick={() => gotoSocial("linkedin")}>
                                <IonImg src={logoLinkedin} className="p2c-social"/>
                            </IonButton>
                        
                            <IonButton className="footer-social-btn" fill="clear" onClick={() => gotoSocial("twitter")}>
                                <IonImg src={logoTwitter} className="p2c-social"/>
                            </IonButton>
                            
                        </IonRow>                    

                        <IonRow className="ion-align-items-start">
                            
                            {
                                appState.currPage !== "contactus" ? 
                                <div >
                                    
                                <IonButton  shape="round" 
                                            className="footer-btn-mobile"
                                            onClick={() => appState.navigate("contactus")}>
                                    Lets Talk
                                </IonButton>
                                
                                </div>
                                : <></>
                            }     
                            
                        </IonRow>      
                    </div>   
                </div> 
            </IonCardContent>
        </IonCard>      
    )
}

const PrivacyInfo = () => {
    return (
        <IonCard className="footer-color hide-card-border " >
            <IonCardHeader><h2 className="footer-font-color">Privacy</h2></IonCardHeader>
            <IonCardContent >                
                We are obliged to follow relevant National and State Acts and principles relating to provacy/digital privacy.
                <br/>
                <br/>
                
                {appState.currPage !== "privacy" ?
                    <IonButton  shape="round" 
                                className="footer-btn-mobile"
                                onClick={() => appState.navigate("privacy")}>
                                Privacy Policy
                    </IonButton>
                : null}
                <br/>
                <br/>
                <p>
                    © 2022 All rights reserved
                </p>                                               
            </IonCardContent>
        </IonCard>
    )
}

const AssociationImgs = () => {
    return(
    <IonCard className="card-no-border">    
        <IonCardContent>            
            <IonGrid >
                <IonRow className="ion-align-items-center ion-justify-content-center">  
                    <IonCol className="center-img" size="3">
                        <IonImg src={TasICTImg} className="img1"/>
                    </IonCol>   
                    <IonCol className="center-img" size="3" >
                        <IonImg src={PMIImg} className="img"/>
                    </IonCol>  
                    <IonCol className="center-img" size="3" >
                        <IonImg src={AIPMImg} className="img"/>
                    </IonCol>         
                    <IonCol className="center-img" size="3" >
                        <IonImg src={AIDHImg} className="img"/>
                    </IonCol>  
                </IonRow>
            </IonGrid>
        </IonCardContent>
    </IonCard>
    )
}
const Footer: React.FC = () => {
  return (  
        <>  
        <IonGrid className="desktop-only footer-color" >
            <IonRow>
                <IonCol>
                    {AssociationImgs()}   
                </IonCol>
            </IonRow>
            <IonRow >
                <IonCol>
                    {ContactInfo()}      
                </IonCol>
                <IonCol>
                    {PrivacyInfo()}
                </IonCol>            
            </IonRow>
        
        </IonGrid>
        <IonGrid className="mobile-only footer-color" >
            <IonRow>
                <IonCol>
                {AssociationImgs()}   
                </IonCol>
            </IonRow>
            <IonRow >
                <IonCol >
                    {ContactInfo()}        
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol >
                    {PrivacyInfo()}
                </IonCol>            
            </IonRow>
        
        </IonGrid>            
    </>
  );
};

export default observer(Footer);
