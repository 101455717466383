import React, {useState} from 'react';

import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';
import { IonInput, IonButton, IonItem, IonLabel, IonTextarea } from '@ionic/react';
import { IonGrid, IonRow, IonCol, IonToast } from '@ionic/react';

import { observer} from 'mobx-react-lite';
import appState  from '../state/State';

import Footer from '../components/Footer';

import './ContactUs.css';

const ContactUs: React.FC = () => {
  
  //const [present, dismiss] = useIonToast();
  const [showToast, setShowToast] = useState(false);

  const sendMsg = async () => {
    if (appState.validateContactInfo() === true) {
      
      appState.sendEmail();
      setShowToast(true)
      console.log("ContactUs:sendMsg")
      appState.clearContactInfo();
    }        
  }

  const ContactUsForm = () => {
      return (     
        <div >       
        <IonCard>
          <IonCardHeader><h1><b>Contact Us</b></h1><br/></IonCardHeader>
          <IonCardContent>
          <div >
            <IonCard className="contact-form">
              <IonItem lines="inset">
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput name="name" 
                          autofocus={true}
                          inputmode="text"
                          onIonChange={e => appState.updateContactInfo("name", e.detail.value!)} 
                          value={appState.contactInfo.name}>              
                </IonInput>
                {appState.contactInfoErrors.name !== '' && <div className="contactus-error">{appState.contactInfoErrors.name}</div>}
              </IonItem>
              <IonItem lines="inset">
                <IonLabel position="stacked">Company</IonLabel>
                <IonInput name="company" 
                          inputmode="text"
                          onIonChange={e => appState.updateContactInfo("company", e.detail.value!)} 
                          value={appState.contactInfo.company}>
                </IonInput>          
                {appState.contactInfoErrors.company !== '' && <div className="contactus-error">{appState.contactInfoErrors.company}</div>}
              </IonItem>        
              <IonItem lines="inset">
                <IonLabel position="stacked">Contact Number</IonLabel>
                <IonInput name="contact_number" 
                          inputmode="tel"
                          onIonChange={e => appState.updateContactInfo("contact_number", e.detail.value!)} 
                          value={appState.contactInfo.contact_number}>
                </IonInput>          
                {appState.contactInfoErrors.contact_number !== '' && <div className="contactus-error">{appState.contactInfoErrors.contact_number}</div>}
              </IonItem>
              <IonItem lines="inset">
                <IonLabel position="stacked">Email</IonLabel>
                <IonInput name="email" 
                          inputmode="email"
                          onIonChange={e => appState.updateContactInfo("email", e.detail.value!)} 
                          value={appState.contactInfo.email}>
                </IonInput>          
                {appState.contactInfoErrors.email !== '' && <div className="contactus-error">{appState.contactInfoErrors.email}</div>}
              </IonItem>
              <IonItem lines="inset">
                <IonLabel position="stacked">Message</IonLabel>
                <IonTextarea  name="message"  
                              auto-grow="true"  
                              onIonChange={e => appState.updateContactInfo("message", e.detail.value!)} 
                              value={appState.contactInfo.message}>              
                </IonTextarea>
                {appState.contactInfoErrors.message !== '' && <div className="contactus-error">{appState.contactInfoErrors.message}</div>}
              </IonItem>

              <IonRow className="ion-hide-md-up">
                <IonCol size="6">
                  <IonButton className="contactus-button" color="success" onClick={() => sendMsg()}>Submit</IonButton>  
                </IonCol>                
                <IonCol size="6" className="ion-align-self-right">                  
                  <IonButton className="contactus-button" color="success" onClick={() => appState.clearContactInfo()}>Clear</IonButton>                    
                </IonCol>
              </IonRow>

              <IonRow className="ion-hide-md-down">
                <IonCol size="3">
                  <IonButton className="contactus-button" color="success" onClick={() => sendMsg()}>Submit</IonButton>  
                </IonCol>                
                <IonCol size="3" className="ion-align-self-right">                  
                  <IonButton className="contactus-button" color="success" onClick={() => appState.clearContactInfo()}>Clear</IonButton>                    
                </IonCol>
                <IonCol size="6">
                </IonCol>
              </IonRow>        
            </IonCard>
          </div>
          </IonCardContent>
        </IonCard>   
        </div>     
    )
  };

  return (          
    <>
      <IonToast         
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Thanks for contacting Phase 2 Consulting. </br><br/>We will be in touch soon."
        duration={2000}/>

        <div className="ion-hide-md-down">
            <IonGrid >
                <IonRow className="contactus-area">
                    <IonCol size="8" className="ion-align-items-center ion-justify-content-center">
                        {ContactUsForm()}             
                    </IonCol>
                             
                    <IonCol size="4" >
                        
                    </IonCol>
                </IonRow>
                
                <IonRow>
                    <Footer/>
                </IonRow>
            </IonGrid>
        </div>
        <div className="ion-hide-md-up">
            <IonGrid >
                <IonRow >
                    <IonCol size="12" >
                        {ContactUsForm()}               
                    </IonCol>                                              
                </IonRow>
                <IonRow>
                    <Footer/>
                </IonRow>
            </IonGrid>
        </div>        
    </>
  );  
}
export default observer(ContactUs);

/*
return (
    <div className={appState.currPage === 'contactus' ? "enter" : "exit"}>
    <IonCard>
      <IonCardHeader><h1><b>Contact Us</b></h1><br/></IonCardHeader>
        <IonCardContent>
        <div className="contact-form">
          <IonCard>
            <IonItem lines="inset">
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput name="name" 
                        autofocus={true}
                        inputmode="text"
                        onIonChange={e => appState.updateContactInfo("name", e.detail.value!)} 
                        value={appState.contactInfo.name}>              
              </IonInput>
              {appState.contactInfoErrors.name !== '' && <div className="error">{appState.contactInfoErrors.name}</div>}
            </IonItem>
            <IonItem lines="inset">
              <IonLabel position="stacked">Company</IonLabel>
              <IonInput name="company" 
                        inputmode="text"
                        onIonChange={e => appState.updateContactInfo("company", e.detail.value!)} 
                        value={appState.contactInfo.company}>
              </IonInput>          
              {appState.contactInfoErrors.company !== '' && <div className="error">{appState.contactInfoErrors.company}</div>}
            </IonItem>        
            <IonItem lines="inset">
              <IonLabel position="stacked">Contact Number</IonLabel>
              <IonInput name="contact_number" 
                        inputmode="tel"
                        onIonChange={e => appState.updateContactInfo("contact_number", e.detail.value!)} 
                        value={appState.contactInfo.contact_number}>
              </IonInput>          
              {appState.contactInfoErrors.contact_number !== '' && <div className="error">{appState.contactInfoErrors.contact_number}</div>}
            </IonItem>
            <IonItem lines="inset">
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput name="email" 
                        inputmode="email"
                        onIonChange={e => appState.updateContactInfo("email", e.detail.value!)} 
                        value={appState.contactInfo.email}>
              </IonInput>          
              {appState.contactInfoErrors.email !== '' && <div className="error">{appState.contactInfoErrors.email}</div>}
            </IonItem>
            <IonItem lines="inset">
              <IonLabel position="stacked">Message</IonLabel>
              <IonTextarea  name="message"  
                            auto-grow="true"  
                            onIonChange={e => appState.updateContactInfo("message", e.detail.value!)} 
                            value={appState.contactInfo.message}>              
              </IonTextarea>
              {appState.contactInfoErrors.message !== '' && <div className="error">{appState.contactInfoErrors.message}</div>}
            </IonItem>
            <IonRow>
              <IonCol size="3">
                <IonButton color="success" onClick={() => appState.submitContactInfo()}>Submit</IonButton>  
              </IonCol>
              <IonCol size="6">
              </IonCol>
              <IonCol size="3" className="ion-align-self-right">
                <div className="right">
                  <IonButton color="success" onClick={() => appState.clearContactInfo()}>Clear</IonButton>  
                </div>
              </IonCol>
            </IonRow>        
          </IonCard>
        </div>
        </IonCardContent>
    </IonCard>

    <Footer/>

    </div>
  );
*/