import React from "react";

import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonButton } from '@ionic/react';

import { IonImg } from '@ionic/react';

import Clients from '../components/Clients';
import Footer from '../components/Footer';
import MelImgUrl from '../assets/Mel500x500.png';
import logoTwitter from '../assets/TwitterBlueH300.png';
import logoLinkedin from '../assets/LinkedInBlueH300.png';

import './AboutUs.css';

import { observer} from 'mobx-react-lite';
//import appState  from '../state/State';


interface Person {
    name:string;
    position:string;
    desc:string
    imageUrl: string;
}

const Mel :Person = {
    name:"Melanie Strachan",
    position:"Managing Director",
    desc:"Melanie has a 25+ year history of strategy " + 
        "development and project delivery.  Melanie specialises " +
        "in complex government projects, particularly Health, " +
        "Education and Emergency Management. She has a passion " +
        "for benefits realisation; for seeing organisations achieve outcomes.",
    imageUrl: MelImgUrl
}

const AboutUs: React.FC = () => {

    const gotoSocial = (target:string) => {
        if (target === "twitter") {
            window.open('https://twitter.com/StrachanMelG','_system', 'location=yes'); return false;
        }
        if (target === "linkedin") {
            window.open('https://www.linkedin.com/in/melanie-strachan-a389101','_system', 'location=yes'); return false;
        }
    }

    const AboutUsText = () => {
        return (            
            <IonCard>  
                <IonCardHeader><h1><b>About Us</b></h1><br/></IonCardHeader>              
                <IonCardContent>
                    <p>
                        Phase 2 Consulting can help you uncover the secret to achieving success on your projects.
                        We bring a disciplined approach to transformation projects to help our clients address their 
                        most complex issues.                            
                    </p>
                    <br/>            
                    <p>
                        We work side-by-side with our clients to develop strategies, navigate change and deliver projects to drive transformation.
                    </p>
                </IonCardContent>
            </IonCard>           
        )
    };

    const TeamMemberDetails = (person:Person) => {
        return (
            <IonCard>
            <IonGrid >
                <IonRow className="ion-hide-lg-down">
                    <IonCol size="4">
                       
                                <IonImg src={person.imageUrl} />
                        
                    </IonCol>
                    <IonCol>
                        <IonCardContent>
                            <h1>{person.name}</h1><br/>
                            <h2>{person.position}</h2><br/>
                            <p>{person.desc}</p>
                        </IonCardContent>
                        <IonRow className="ion-align-items-start`">
                        
                            <IonButton  className="aboutus-social-btn" fill="clear" onClick={() => gotoSocial("linkedin")}>
                                <IonImg src={logoLinkedin} className="aboutus-social-icon"/>
                            </IonButton>
                        
                            <IonButton className="aboutus-social-btn" fill="clear" onClick={() => gotoSocial("twitter")}>
                                <IonImg src={logoTwitter} className="aboutus-social-icon"/>
                            </IonButton>
                            
                        </IonRow> 
                    </IonCol>
                </IonRow>

                <IonRow className="ion-hide-lg-up">
                    <IonCol size='4'>
                        <IonImg src={person.imageUrl} />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-hide-lg-up">
                    <IonCol>
                        <IonCardContent>
                                <h1>{person.name}</h1><br/>
                                <h2>{person.position}</h2><br/>
                                <p>{person.desc}</p>
                        </IonCardContent>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-hide-lg-up ion-align-items-start`">
                
                    <IonButton  className="aboutus-social-btn" fill="clear" onClick={() => gotoSocial("linkedin")}>
                        <IonImg src={logoLinkedin} className="aboutus-social-icon"/>
                    </IonButton>
                
                    <IonButton className="aboutus-social-btn" fill="clear" onClick={() => gotoSocial("twitter")}>
                        <IonImg src={logoTwitter} className="aboutus-social-icon"/>
                    </IonButton>
                    
                </IonRow> 
            </IonGrid>
            </IonCard>
        )
    }

    return (          
            <>
           <IonGrid className="ion-hide-md-down">
                <IonRow className="aboutus-content-padding">
                    <IonCol>
                        <IonRow>
                            {AboutUsText()}
                        </IonRow>
                        <IonRow>
                            <Clients/>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        {TeamMemberDetails(Mel)}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Footer/>
                    </IonCol>
                </IonRow>
           </IonGrid>
           <IonGrid className="ion-hide-md-up">
                <IonRow >
                    <IonCol size="12">                        
                        {AboutUsText()}
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="12">
                        {TeamMemberDetails(Mel)}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <Clients/>
                    </IonCol>                                            
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Footer/>
                    </IonCol>
                </IonRow>
           </IonGrid>
           </>
  );
};

export default observer(AboutUs);

