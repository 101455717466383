

import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';

import { observer} from 'mobx-react-lite';
//import appState  from '../state/State';

import Footer from '../components/Footer';

import './Services.css';

const IndustriesInfo = () => {
    return (
        <IonCard >
            <IonCardHeader><h1><b>Services</b></h1></IonCardHeader>     
           
            <IonCardContent >
            
                <p className="indent">
                Phase 2 Consulting have a team of expert consultants with a diverse range of skills and experience
                across a range of industries.
                </p>
                <br/>

                <div >                    
                    <h1><b>Industries</b></h1>
                    <br/>
                    <ul>                        
                        <li>Local Government</li>
                        <li>State Government</li>
                        <li>Health and Community Care</li>
                        <li>Police, Justice and Corrective Services</li>
                        <li>Emergency Management</li>
                        <li>Education</li>
                        <li>Construction</li>
                        <li>Utilities</li>
                        <li>Banking and Finance</li>
                        <li>Insurance</li>                        
                        <li>Hotel Management and Hospitality</li>                        
                    </ul>
                </div> 
            </IonCardContent>
        </IonCard>      
    )
}

const CapabilitiesInfo = () => {
    return (
        <IonCard className="services-hide-card-border">           
            <IonCardContent>                
                <div >
                    <h1><b>Capabilities</b></h1>
                    <br/>
                    <ul>                        
                        <li>Business Analysis</li>
                        <li>Business Process Re-engineering</li>
                        <li>Change Management</li>
                        <li>Stakeholder Engagement and Communications</li>
                        <li>Strategy Development</li>
                        <li>Project Management and Delivery</li>
                        <li>Benefits Realisation</li>
                        <li>Business Transformation</li>
                        <li>Leadership and Business Training</li>
                        <li>Application and Website Development</li>
                        <li>Mobile Application Development</li>
                        <li>Solution Testing</li>
                        <li>Agile Software Development and Scrum</li>
                        <li>Data Analysis and Modelling</li>
                        <li>Project Management Office, Enterprise Project Management Office and Value Delivery</li>                        
                    </ul>
                </div>                
            </IonCardContent>
        </IonCard>
    )
}

const Services: React.FC = () => {
  return (  
    <>    
        <IonGrid className="ion-hide-md-down">
            <IonRow className="services-content-padding">
                <IonCol>
                    {IndustriesInfo()}      
                </IonCol>
                <IonCol>
                    {CapabilitiesInfo()}
                </IonCol>     
                      
            </IonRow>
            <IonRow>            
                <IonCol>
                    <Footer/>
                </IonCol> 
            </IonRow>
        </IonGrid>
        <IonGrid className="ion-hide-md-up">
            <IonRow >
                <IonCol >
                    {IndustriesInfo()}        
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol >
                    {CapabilitiesInfo()}
                </IonCol>            
            </IonRow>
            <IonRow>
                <Footer/>
            </IonRow>
        </IonGrid>            
    </>
  );
};

export default observer(Services);
