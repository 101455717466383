import React, {useState, useEffect} from 'react';

//import { Virtuoso } from 'react-virtuoso';

import { IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonButton } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';


import Footer from '../components/Footer';

import './Blogs.css';
import { Blog }  from '../state/State';
import { observer} from 'mobx-react-lite';
import appState  from '../state/State';

const Blogs: React.FC = () => {

    const [currBlogID, setCurrBlogID] = useState("");
    
    useEffect(() =>  {
       
        //appState.getBlogs();
        if (appState.blogList.length === 0)
            appState.getContentful();
        
        console.log("Blogs:userEffect:",appState.blogList)  
                   
    },[]);

    const BlogSummary = (b:Blog) => {
        console.log("BlogSummary:", b);
         
        return (
            <IonCard id={b.id + "-summary"}>
                <IonCardHeader><h1><b>{b.title}</b></h1><br/></IonCardHeader>      
                <IonCardSubtitle>{b.summary}</IonCardSubtitle>       
                <IonButton onClick={() => {setCurrBlogID(b.id || "")}}>Details</IonButton>                    
            </IonCard>               
        )
    } 

    const BlogDetails = () => {
        const b = appState.blogList.find((b) => {return b.id === currBlogID})      
        if (!b) return;                
        return (                 
            <IonCard id={b.id + "-details"}>  
                <IonCardHeader><h1><b>{b.title}</b></h1><br/></IonCardHeader>   
                <IonCardSubtitle>{b.summary}</IonCardSubtitle>                
                <IonCardContent>
                    <p>
                        {b.summary}.                            
                    </p>                    
                </IonCardContent>
                <IonButton onClick={() => {setCurrBlogID("")}}>Close</IonButton>
            </IonCard>                      
        )
    };
    
    return (
        <div >
             <div className={currBlogID === "" ? "enter" : "exit"}>                  
                <IonGrid>
                    <IonRow className="blog-area">                        
                        {                            
                            appState.blogList.map((b) => {
                                return (
                                <IonCol size-lg="4" size-sm="6" size-xs="12" key={b.id} id={b.id}>
                                    {BlogSummary(b)}
                                </IonCol>
                                )
                                })
                        }                        
                    </IonRow>
                    <IonRow>
                        <IonCol id={'footer'}>
                            <Footer/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>      
            <div className={currBlogID !== "" ? "enter " : "exit"}>  
                <IonGrid>
                    <IonRow className="blog-area">
                        <IonCol >
                            {BlogDetails()}
                        </IonCol>
                        
                    </IonRow>

                    <IonRow>
                        <Footer/>
                    </IonRow>
                </IonGrid>                    
            </div>  
        </div>   
    )
};

export default observer(Blogs);

