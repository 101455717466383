import { makeAutoObservable } from "mobx"
import * as contentful from "contentful";

//import * as blog1 from '../state/blogs1.json';
//import * as blog2 from '../state/blogs2.json';
//import Blogs from "../state/blogs2.json";
// Import the functions you need from the SDKs you need



//
// Firebase 
//
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1iqfJWen-ezUMPGZ5GotrTyapGYP9GR4",
  authDomain: "phase2consulting-23c35.firebaseapp.com",
  projectId: "phase2consulting-23c35",
  storageBucket: "phase2consulting-23c35.appspot.com",
  messagingSenderId: "168711364082",
  appId: "1:168711364082:web:bc3ee0eda3444e4b205f6b",
  measurementId: "G-CTCH4T8NJM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export type Blog = {
    id: contentful.EntryFields.Text;    
    dateCreated?: contentful.EntryFields.Text;
    media?: contentful.EntryFields.Object;
    author?: contentful.EntryFields.Text;
    title?: contentful.EntryFields.Text;
    summary?: contentful.EntryFields.Text;
    rich_content?: contentful.EntryFields.RichText;
    publish?: contentful.EntryFields.Boolean;
}

/*
export interface IBlog {
    id?:string,
    dateCreated?: string,
    media?: string,
    author?: string,
    title?: string,
    summary?: string,
    rich_content?: string,
    publish?: boolean
}
*/

const contactInfoInit = {
    name:'',
    email:'',
    contact_number: '',
    company:'',
    message:''
};


class State {

    constructor() {
        makeAutoObservable(this)
    } 
    
    client = contentful.createClient({
        space: '46c6ega37yv7',
        accessToken: 'EX5q5agBAFH6oT4wjEKmnBbKekENb6T-8XfRFyztGuM',
    });

    NavigationItems = [
        {name: "Home", key:"home", visible:true},
        {name: "Services", key:"services", visible:true},
        {name: "About Us", key:"aboutus", visible:true},
        {name: "Contact Us", key:"contactus", visible:true},
        {name: "Privacy", key:"privacy", visible:false},
        //{name: "Blog", key:"blog"},
    ]

    UXScrollAtTop = true
    scrollFn:any;
    showEmailToast = false;
    blogList: Blog[] = [];

    get MenuItems() {
        return this.NavigationItems.filter((m) => {
            return m.visible
        })
    }

    setScrollAtTop(v:boolean) {
        this.UXScrollAtTop = v
        console.log("setScrollAtTop:", v)
    }

    setShowEmailToast(v:boolean) {
        this.showEmailToast = v;
    }

    setScrollFn(fn:any) {
        if (fn)
            this.scrollFn = fn;        
    }

    scrollToTop() {
        if (this.scrollFn)
            this.scrollFn();               
    }

    currPage="home";

    navigate(pageId: string) {

        if(this.NavigationItems.some(menuItem => menuItem.key === pageId)){
            this.currPage = pageId;

        }
               
        if (this.UXmenuIsOpen)
            this.UXmenuIsOpen = false;        
        console.log("State:currPage:", this.currPage);
        this.scrollToTop(); 

        const analytics = getAnalytics();
        logEvent(analytics, 'navigate_to', { name: pageId});
    }
   
    UXmenuIsOpen = false;

    toggleMenu() {
        this.UXmenuIsOpen = !this.UXmenuIsOpen ;
    }

    
    contactInfoErrors = {...{}, ...contactInfoInit};
    clearErrorInfo() {
        this.contactInfoErrors = {...{},...contactInfoInit};
    }

    contactInfo = {...{}, ...contactInfoInit};

    clearContactInfo() {
        this.contactInfo = {...{},...contactInfoInit};
        this.clearErrorInfo()
    }

    updateContactInfo(field:any, value:any) {
        this.contactInfo = {...this.contactInfo, [field]:value}
        console.log("contactInfo", this.clearContactInfo)
    }


    validateContactInfo() {
        var emailRegEx = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
        this.clearErrorInfo()
        //var errors = {...{}, ...this.contactInfo};
        if (this.contactInfo.name === '') {
            this.contactInfoErrors.name = "Name may not be blank";
        }
        if (this.contactInfo.contact_number === '' && this.contactInfo.email === '') {
            this.contactInfoErrors = {...this.contactInfoErrors, contact_number: "Please enter contact info (email or phone number)"};
        }
        if (this.contactInfo.email !== '') {
          if (!this.contactInfo.email.match(emailRegEx)) {
            
            this.contactInfoErrors = {...this.contactInfoErrors, email: "Invalid email format"};
          }
        }
        if (this.contactInfo.message === '') {
            this.contactInfoErrors = {...this.contactInfoErrors, message: "Please enter a message"};
        }
        var e = {...{}, ...this.contactInfoErrors};
        console.log("contactInfoErrors", e)

        if (this.contactInfoErrors.name === '' &&
            this.contactInfoErrors.company === '' &&
            this.contactInfoErrors.email === '' &&
            this.contactInfoErrors.contact_number === '' &&
            this.contactInfoErrors.message === '') {
                return true;
        }
        return false; 
    }
 
    sendEmail() {
        var url = 'http://localhost:5001/phase2consulting-23c35/us-central1/sendEmailPOST' // ADD YOUR ENDPOINT HERE
        url = "https://us-central1-phase2consulting-23c35.cloudfunctions.net/sendEmailPOST";
        
        
        const data = JSON.stringify(this.contactInfo)
        
        logEvent(analytics, 'send_email', { from: data});

        console.log("Email Data ", data)

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.contactInfo),
            headers:{
              'Content-Type': 'application/json'
            }
            
        })
        .catch(error => console.error('Error:', error));
  
    }
    
    async getContentful () {
    
        console.log("getContentful:1")

        var data = await this.client.getEntries<Blog>()
        
        data.items.forEach((b) => {
            var blog:Blog = {id:""};
            
            blog.author = b.fields.author;
            blog.dateCreated = b.fields.dateCreated;
            blog.id = b.sys.id;
            blog.publish = b.fields.publish;
            blog.rich_content = b.fields.rich_content;            
            blog.summary = b.fields.summary;
            blog.title = b.fields.title;
            
            this.addBlog(blog);
        })
        
        console.log("getContentful:Data", this.blogList)
    };

    addBlog(b:Blog) {
        this.blogList.push(b);
    }
    
}

const appState = new State();

export default appState ;

